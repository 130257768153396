import {
	Menu as MenuIcon
} from '@mui/icons-material';

import {
	AppBar as MuiAppBar,
	Toolbar,
	Typography,
	IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useState } from "react";
import AppDrawer from "./AppDrawer";
import NiftyBar from "./NiftyBar";

const drawerWidth = 240;
const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	transition: theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: `${drawerWidth}px`,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const TopBar = ({ data, isMarketLive, marketStatus, menuShow }) => {

	const [open, setOpen] = useState(false);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const status = data?.data[0].change < 0 ? 'red' : 'green';

	return (
		<>
			<AppBar style={{ background: '#000' }} position="fixed" open={open}>
				<Toolbar style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
					<span style={{ display: 'flex' }}>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="start"
							sx={{ mr: 2, ...(open && { display: 'none' }) }}
						>
							<MenuIcon />
						</IconButton>
						<Typography variant="h6" noWrap component="div">
							Nifty 50
						</Typography>
					</span>
					<NiftyBar menuShow={menuShow} open={open} status={status} data={data} />
					<span>
						<div style={{ padding: '15px' }}>
							<span
								style={{ fontWeight: 'bold', padding: '10px', color: isMarketLive }}
							>
								{marketStatus ? 'Market is open' : 'Market is closed'}
							</span>
						</div>
					</span>
					<span>
						<img
							alt="nifty50"
							style={{ height: '70px', width: '150px', float: 'right' }}
							src="https://rajdhinge.com/trade.png"
						/>
					</span>
				</Toolbar>
			</AppBar >
			<AppDrawer drawerWidth={drawerWidth} open={open} onClose={handleDrawerClose} />
		</>
	);
}

export default TopBar;