
function useStockMarketStatus() {
	const now = new Date();
	const dayOfWeek = now.getDay();
	const hours = now.getHours();
	const minutes = now.getMinutes();

	// Check if it's a weekday (Monday to Friday)
	if (dayOfWeek >= 1 && dayOfWeek <= 5) {
		// Check if it's within trading hours
		if (
			(hours === 9 && minutes >= 15) || // Opening time: 9:15 AM
			(hours > 9 && hours < 15) || // Between 9:15 AM and 3:30 PM
			(hours === 15 && minutes <= 30) // Closing time: 3:30 PM
		) {
			return true;
		}
	}

	return false;
}

export default useStockMarketStatus;