import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';
import {
	QueryClient,
	QueryClientProvider,
} from '@tanstack/react-query'

import MainApp from './component/UI/MainApp';

const queryClient = new QueryClient()

function App() {

	return (
		<div className="App">
			<QueryClientProvider client={queryClient}>
				<MainApp />
			</QueryClientProvider>
		</div>
	);
}

export default App;
