import React from 'react'

const PercentageValueDistributionBar = ({ data, nifty50, postitveValue }) => {
	return (
		<div>
			{data != null && (<div>
				<div style={{ display: 'flex', textAlign: 'center' }}>
					<h1 style={{ width: '100%', color: '#B92636' }}>
						{
							((nifty50?.totalTradedValue - postitveValue) / nifty50?.totalTradedValue * 100).toFixed(2)
						} % ⬇️</h1>
					<h1 style={{ width: '100%', color: '#1B914F' }}>
						{
							(postitveValue / nifty50?.totalTradedValue * 100).toFixed(2)
						} % ⬆️</h1>
				</div>
			</div>)}
		</div>

	)
}

export default PercentageValueDistributionBar