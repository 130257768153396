import { useState } from 'react';

//MUI
import {
	Box,
	CssBaseline,
	Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Custom 
import useFetch from '../hooks/useFetch';
import useStockMarketStatus from '../hooks/useStockMarketStatus';
import DataTable from '../DataVisual/DataTable';
import DataPie from '../DataVisual/DataPie';
import useViewPort from '../hooks/useViewPort';
import TopBar from '../TopBar';
import PercentageValueDistributionBar from '../PercentageValueDistributionBar';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: `-${drawerWidth}px`,
		...(open && {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		}),
	}),
);
const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}));

export default function MainApp() {

	const currentViewPort = useViewPort();
	const [open, setOpen] = useState(false);
	const { isLoading, error, data, isFetching } = useFetch(/*isLive*/);
	const marketStatus = useStockMarketStatus();
	// const [isLive, setIsLive] = useState();

	//Data declare
	let dispType = '';
	let menuShow = '';
	let pieSize = '';
	let postitveValue = 0;

	//Data defination
	const isMobile = currentViewPort <= 768;
	if (isMobile) {
		dispType = 'block';
		menuShow = 'none'
		pieSize = '44vh';
	} else {
		menuShow = 'flex'
		dispType = 'flex';
		pieSize = '77vh';
	}

	const nifty50 = data?.data[0];
	const volumeData = data?.data.slice(1).map((stock) => {
		if (stock?.change > 0) {
			postitveValue += stock?.totalTradedValue;
		}
		return {
			"id": stock?.symbol,
			"label": stock?.symbol + " | " + stock?.change,
			"value": stock?.totalTradedValue.toFixed(2),
			"color": "#97e3d5",
			"change": stock?.change,
			"pChange": stock?.pChange,
			"ltp": stock?.lastPrice
		}
	})
	const fillData = data?.data.slice(1).map((stock) => {
		const color = stock?.change < 0 ? 'loss' : 'profit';
		return {
			match: {
				id: stock?.symbol
			},
			id: color
		}
	})

	const isMarketLive = marketStatus ? 'green' : 'red';

	// Is Loading
	if (isLoading) {
		return <div style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: '100vh'
		}}>
			<h1>Loading 50 Bulls...</h1>
		</div >
	}

	//Render
	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<TopBar menuShow={menuShow} data={data} isMarketLive={isMarketLive} marketStatus={marketStatus} />
			<Main open={open}>
				<DrawerHeader />
				<div style={{ display: dispType }}>
					<div style={{ flexBasis: '50%' }}>
						<Accordion defaultExpanded>
							<AccordionSummary expandIcon={<ExpandMoreIcon />}>
								<Typography><strong>Nifty 50 Index</strong></Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography><DataTable data={volumeData} /></Typography>
							</AccordionDetails>
						</Accordion>
					</div>
					<div>
					</div>
					<div style={{ flexBasis: '50%' }}>
						<PercentageValueDistributionBar data={data} nifty50={nifty50} postitveValue={postitveValue} />
						<div>
							<Box style={{ height: pieSize, width: '100%', display: 'flex' }}>
								{volumeData != null && <DataPie data={volumeData} fillData={fillData} />}
							</Box>
						</div>
					</div>
				</div >
			</Main>
		</Box >
	);
}
