import {
	useQuery,
} from '@tanstack/react-query'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import axios from 'axios'
import useStockMarketStatus from './useStockMarketStatus'
const useFetch = () => {
	const isStockMarketOpen = useStockMarketStatus();
	return useQuery({
		queryKey: ['repoData'],
		queryFn: async () =>
			axios
				.get('https://nifty.rajdhinge.com/nifty50.php')
				.then((res) => res.data),
		refetchInterval: isStockMarketOpen ? 5000 : false,
	})
}

export default useFetch;