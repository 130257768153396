import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { darken, lighten, styled } from '@mui/material/styles';

const getBackgroundColor = (color, mode) =>
	mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
	mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color, mode) =>
	mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color, mode) =>
	mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
	'& .super-app-theme--profit': {
		backgroundColor: getBackgroundColor(
			theme.palette.success.main,
			theme.palette.mode,
		),
		'&:hover': {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.success.main,
				theme.palette.mode,
			),
		},
		'&.Mui-selected': {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.success.main,
				theme.palette.mode,
			),
			'&:hover': {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.success.main,
					theme.palette.mode,
				),
			},
		},
	},
	'& .super-app-theme--loss': {
		backgroundColor: getBackgroundColor(
			theme.palette.error.main,
			theme.palette.mode,
		),
		'&:hover': {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.error.main,
				theme.palette.mode,
			),
		},
		'&.Mui-selected': {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.error.main,
				theme.palette.mode,
			),
			'&:hover': {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.error.main,
					theme.palette.mode,
				),
			},
		},
	},
}));

export default function DataTable({ data }) {

	const columns = [
		{ field: 'id', headerName: 'Symbol', width: 130 },
		{ field: 'change', headerName: 'Change Value', type: 'number', width: 130 },
		{ field: 'pChange', headerName: 'Change %', type: 'number', width: 130 },
		{ field: 'value', headerName: 'Value (Lakhs)', type: 'number', width: 130 },
		{ field: 'ltp', headerName: 'LTP', type: 'number', width: 90, },
	];

	if (data === undefined) {
		return false;
	}
	const rows = [
		...data
	];

	return (
		<Box sx={{ display: 'grid', gridTemplateColumns: '1fr' }} style={{ height: 'auto', width: '100%' }}>
			<StyledDataGrid
				rows={rows}
				columns={columns}
				getRowClassName={(params) => {
					const color = params.row.change < 0 ? 'loss' : 'profit';
					return `super-app-theme--${color}`
				}}
				initialState={{
					pagination: {
						paginationModel: { page: 0, pageSize: 10 },
					},
				}}
				pageSizeOptions={[10, 20, 30, 40, 50]}
			// checkboxSelection
			/>
		</Box>
	);
}