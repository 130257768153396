import { useState, useEffect } from 'react'

function Clock() {
	const [time, setTime] = useState();
	let intervalId;
	useEffect((date) => {

		intervalId = setInterval(
			() => {
				date = new Date();
				setTime(date.toLocaleTimeString())
			},
			1000)

		return () => {
			clearInterval(intervalId);
		}
	}, [])

	return (
		<span>
			{time}
		</span>
	);
};

export default Clock;