import React from 'react'
import Clock from './Misc/Clock'

const NiftyBar = ({ menuShow, open, status, data }) => {
	return (
		<>
			{!open && (
				<span style={{ display: menuShow }}>
					<div style={{ padding: '15px', fontSize: '1.67vh' }}>
						<strong>Change value:</strong>
						<span style={{ fontWeight: 'bold', color: status, padding: '10px' }}>
							{data?.data[0].change.toFixed(2)}
						</span>
					</div>
					<div style={{ padding: '15px', fontSize: '1.67vh' }}>
						<strong>Change %:</strong>
						<span style={{ fontWeight: 'bold', color: status, padding: '10px' }}>
							{data?.data[0].pChange.toFixed(2)} %
						</span>
					</div>
					<div style={{ padding: '15px', fontSize: '1.67vh' }}>
						<strong>Value (Lakhs):</strong>
						<span style={{ fontWeight: 'bold', color: status, padding: '10px' }}>
							{data?.data[0].totalTradedValue.toFixed(2)}
						</span>
					</div>
					<div style={{ padding: '15px', fontSize: '1.67vh' }}>
						<strong>LTP :</strong>
						<span style={{ fontWeight: 'bold', color: status, padding: '10px' }}>
							{data?.data[0].lastPrice}
						</span>
					</div>
					<div style={{ padding: '15px', fontSize: '1.67vh' }}>
						<strong>Time :</strong>
						<span style={{ fontWeight: 'bold', padding: '10px' }}>
							<Clock />
						</span>
					</div>
				</span>)}
		</>
	)
}

export default NiftyBar