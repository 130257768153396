import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBE7iQ4RLxsScsoP_kqQxfyAhoRuxBTv-I",
  authDomain: "nifty50-1892f.firebaseapp.com",
  projectId: "nifty50-1892f",
  storageBucket: "nifty50-1892f.appspot.com",
  messagingSenderId: "778869189245",
  appId: "1:778869189245:web:be49dfe194abe754c0df14",
  measurementId: "G-G4P7N4MNZ5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const database = getFirestore(app);
