import { ResponsivePie } from '@nivo/pie'
import { Button, Box, Card } from '@mui/material';
import { useState } from 'react';
const DataPie = ({ data, fillData/* see data tab */ }) => {
	const [sortState, setSortState] = useState(false);

	const handleSort = () => {
		setSortState(!sortState);
	}

	return (
		<div style={{ flexBasis: '100%' }}>
			<Box>
				<Button onClick={handleSort}> {sortState ? 'Sort by Profit Loss' : 'Sort by Value'}</Button>
			</Box>
			<ResponsivePie
				defs={[
					{
						id: 'profit',
						type: 'patternLines',
						color: '#1B914F',
						spacing: 1
					},
					{
						id: 'loss',
						type: 'patternLines',
						color: '#B92636',
						spacing: 1
					}
				]}

				fill={[...fillData]}
				data={data}
				margin={{ top: 0, right: 0, bottom: 70, left: 10 }}
				// innerRadius={0.5}
				padAngle={1.5}
				cornerRadius={3}
				activeOuterRadiusOffset={8}
				arcLinkLabelsSkipAngle={100}
				arcLabelsSkipAngle={100}

				sortByValue={sortState}

				tooltip={
					e => {
						let { datum: t } = e;
						return (
							<div>
								<Box>
									<Card>
										<strong>
											<div>{t.data.id}</div>
											<div>Change value: {t.data.change}</div>
											LTP : {t.data.ltp}
										</strong>
									</Card>
								</Box >
							</div >);
					}
				}

			/>
		</div>
	)

}

export default DataPie;